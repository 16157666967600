/* 
h1, p {
  font-family: Lato;
} */

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#root {
  font-family: Roboto;
}

.college-link, .conference-link {
  cursor:pointer;
  color:rgb(47 103 166);
}

.college-link:hover, .conference-link:hover  {
  text-decoration: underline;
}


.week-btn-right {
  margin-right: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.week-btn-left {
  margin-left: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.week-btn-right:focus, .week-btn-left:focus {
  outline: none;
}

@media (min-width: 591px) {
  /* Styles to apply when screen width is above 590px */
  .ci-grid {
    overflow-x: hidden !important;
  }
}

.ci-grid::-webkit-scrollbar {
  background-color:#00171F;
  width:6px;
  height:6px;
}

.ci-grid::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #edeaea
}

.ci-grid::-webkit-scrollbar-button {display:none}



.filterInput {
  color:white;
}

.filterInput:hover::placeholder {
  color:#00A7E1;
}

.filterInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.filterInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.filterInput::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}


.ci-button:hover {
  background-color:#00A7E1!important;
}

.ci-text-button:hover {
  color:#00A7E1!important;
}